import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "./script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import CallAction from "../elements/callaction/CallAction";
// import Team from "../blocks/team/TeamOne";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";



import {
  FiCast,
  FiServer,
  FiLayers,
  FiUsers,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";
import Brand from "../elements/Brand";

import about from "../assets/images/about/about-4.png";
import about2 from "../assets/images/about/about-3.png";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--32",
    category: "",
    title: "CAIRQ CONSEIL",
    description:
      "Cabinet d'Experts Comptables Agréés par la CEMAC.",
    buttonText: "Contactez-nous",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--31",
    category: "",
    title: "CAIRQ CONSEIL.",
    description:
      "Plus de trente ans d'expérience professionelle.",
    buttonText: "En savoir plus",
    buttonLink: "/about",
  },
];

const ServiceListOne = [
  {
    icon: <FiLayers />,
    title: "AUDIT",
    description:
      "Un audit est un examen systématique et indépendant des régistres financiers, des opérations et des contrôles internes d'une organisation, effectué par un auditeur."
  },
  {
    icon: <FiLayers />,
    title: "COMPTABILITE",
    description:
      "La tenue de la comptabilité et l'établissement du bilan représente le métier de base de l'expert comptable. Cette mission est adaptée à chaque entreprise."
  },
  {
    icon: <FiLayers />,
    title: "CONSEIL",
    description:
      "CAIRQ CONSEIL fournit des conseils et une assistance professionnels pour aider les organisations à gérer leurs audits financiers, à améliorer les contrôles internes et à assurer la conformité aux exigences réglementaires et légales.",
  },
  {
    icon: <FiUsers />,
    title: "TAX & LEGAL",
    description:
      "Les questions fiscales et juridiques sont étroitement liées et jouent un rôle essentiel dans l'exploitation et la gestion des entreprises.",
  },
];

// const starndardService = [
//   {
//     image: "01",
//     title: "Thinking Development",
//     description: "I throw myself down among the tall grass by the stream",
//   },
//   {
//     image: "02",
//     title: "Business Consulting",
//     description: "I throw myself down among the tall grass by the stream",
//   },
//   {
//     image: "03",
//     title: "Biseness Development",
//     description: "I throw myself down among the tall grass by the stream",
//   },
// ];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
      "The Ultimate Deal On business",
    ];
    var namesItemTwo = [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ];

    // const PostList = BlogContent.slice(0, 3);

    return (
      <Fragment>
        <Helmet pageTitle="CAIRQ CONSEIL" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img className="w-100" src={about} alt="About Images" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">A propos de nous</h2>
                    <p>
                    Le Cabinet CAIRQ Conseil est dirigé par deux Associés en la personne
                    de Monsieur Rémy IKONGA et Monsieur Hyppolyte Hilaire QUENUM.
                    Ils sont Experts Comptables Agréés par la Communauté Economique et
                    Monétaire de l'Afrique Centrale(CEMAC).
                    </p>
                  </div>
                  {/* <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div> */}
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      En Savoir Plus
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="/service">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Team Area  */}
        /
        {/* End Team Area  */}
        
        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Brand branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        
        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <Footer />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
